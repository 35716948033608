<template lang="pug">
.team(:style="{ marginTop: $store.state.navbarHeight + 'px' }")
  .team_info.pt-10
    v-container.align-center.px-3
      v-row.team_mobile.d-sm-none.mx-0
        .team_title.ml-5
          h5 {{ team.name }}
          span.text-subtitle-1.text_light_gray {{ getRankText(team.rank) }} | {{ team.wins_losses }}
      v-row.mx-0
        .team_content
          h5.d-none.d-sm-inline-block {{ team.name }}
          span.d-none.d-sm-inline-block.ml-2.text-subtitle-1.text_light_gray {{ getRankText(team.rank) }} | {{ team.wins_losses }}
          .board.d-flex.mt-7
            .board_item(v-for="(item, index) in team.leaders", :key="index")
              h6.text_light_gray {{ item.title }}
              h4 {{ getRankText(item.rank) }}
              p {{ item.value }}
                span(v-if="index < 2") /單場平均
    v-container.mt-7.pa-0(fluid)
      v-sheet(color="grey lighten-3")
        v-container.pa-0
          v-tabs.sheet(
            v-model="pageIndex",
            background-color="grey lighten-3",
            show-arrows
          )
            v-tabs-slider(hidden="true")
            v-tab(
              v-for="(tabItem, index) in tabList",
              :key="index",
              :ripple="false",
              @click="toPage(index)"
            ) {{ tabItem }}

    season-segment-selector(
      :leagueId="leagueId",
      :seasonSegmentId="seasonSegmentId",
      @leaf-node-clicked="leafNodeClicked"
    )

  v-main
    transition(name="page", mode="out-in")
      router-view
  custom-footer
</template>

<script>
import { getTeam } from "@/api/league";
import CustomFooter from "@/components/common/Footer";
import SeasonSegmentSelector from "@/components/league/SeasonSegmentSelector";

export default {
  name: "LeagueTeam",
  metaInfo() {
    return {
      title: this.metaData.title,
      meta: [
        {
          name: "description",
          content: this.metaData.description,
        },
        {
          name: "keywords",
          content: this.metaData.keywords,
        },
        { property: "og:site_name", content: this.metaData.siteTitle },
        {
          property: "og:title",
          content: this.metaData.title,
        },
        {
          property: "og:description",
          content: this.metaData.description,
        },
        { name: "og:url", content: this.metaData.url },
        { name: "og:image", content: this.metaData.image },
        { name: "smo_type", content: this.metaData.smoType },
      ],
    };
  },
  components: {
    CustomFooter,
    SeasonSegmentSelector,
  },
  data() {
    return {
      seasonSegmentId:
        Number(this.$route.params.seasonSegmentId) > 0
          ? Number(this.$route.params.seasonSegmentId)
          : null,
      team: {},
      pageIndex: -1,
      tabList: ["戰績", "數據", "陣容"],
      metaData: {
        siteTitle: "籃球數據分析Game Changer",
        title: "籃球數據分析Game Changer",
        default_title: "籃球數據分析Game Changer",
        description:
          "提供台灣籃球完整資訊，包含中文字幕影音、精彩Highlights、文字轉播、最新消息、精彩圖輯、球隊與球員詳細數據、賽程比分、名家專欄等內容。",
        keywords: "Game changer,籃球數據分析,戰術分享,球員履歷",
        url: "https://www.gamechanger.tw/",
        image: "",
        smoType: "website",
      },
    };
  },
  computed: {
    leagueId() {
      return Number(this.$route.params.leagueId);
    },
    teamId() {
      return Number(this.$route.params.teamId);
    },
  },
  watch: {
    seasonSegmentId() {
      this.initData();
    },
    teamId() {
      this.initData();
    },
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.leagueId < 1 || this.seasonSegmentId < 1 || this.teamId < 1)
        return;
      this.setPage(this.$route.name);
      this.toPage(this.pageIndex);
      this.getTeamApi();
    },
    async getTeamApi() {
      const params = {
        team_id: this.teamId,
        season_segment_id: this.seasonSegmentId,
      };
      const response = await getTeam(params);
      this.team = response.data.team;
      this.metaData.title = `${this.team.name} | 籃球數據分析Game Changer`;
    },
    leafNodeClicked(node) {
      this.seasonSegmentId = node.id;
    },
    getRankText(rank) {
      if (rank == 1) {
        return "1st";
      } else if (rank == 2) {
        return "2nd";
      } else if (rank == 3) {
        return "3rd";
      } else {
        return rank + "th";
      }
    },
    setPage(name) {
      switch (name) {
        case "LeagueTeamRecords":
          this.pageIndex = 0;
          break;
        case "LeagueTeamStatistics":
          this.pageIndex = 1;
          break;
        case "LeagueTeamRoster":
          this.pageIndex = 2;
          break;
      }
    },
    toPage(index) {
      this.pageIndex = index;

      switch (index) {
        case 0:
          this.$router.push({
            name: "LeagueTeamRecords",
            params: {
              leagueId: this.leagueId,
              seasonSegmentId: this.seasonSegmentId,
              teamId: this.teamId,
            },
          });
          break;
        case 1:
          this.$router.push({
            name: "LeagueTeamStatistics",
            params: {
              leagueId: this.leagueId,
              seasonSegmentId: this.seasonSegmentId,
              teamId: this.teamId,
            },
          });
          break;
        case 2:
          this.$router.push({
            name: "LeagueTeamRoster",
            params: {
              leagueId: this.leagueId,
              seasonSegmentId: this.seasonSegmentId,
              teamId: this.teamId,
            },
          });
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.board_item {
  width: 150px;

  h6 {
    font-size: 18px;
    color: $deepGray;
  }

  h4 {
    color: $primary;
    font-weight: bold;
    font-style: italic;
    font-family: Helvetica;
    font-size: 34px;
    line-height: 50px;
  }

  p {
    font-size: 14px;
    color: $lightGray;

    span {
      font-weight: normal;
      font-size: 12px;
    }
  }
}

.team_logo {
  width: 180px;
  height: auto;

  img {
    width: 100%;
    height: 100%;
  }
}

.team_content {
  margin-left: 60px;
  font-size: 24px;
  font-weight: bold;
  flex-shrink: 1;
  flex-grow: 1;
}

@media (max-width: 959px) {
  .board_item {
    width: 25%;
    flex-shrink: 1;

    h6 {
      font-size: 14px;
    }

    h4 {
      font-size: 28px;
      line-height: 34px;
    }
  }

  .team_logo {
    width: 20vw;

    flex-shrink: 0;
  }

  .team_content {
    margin-left: 10vw;

    h5 {
      font-size: 18px;
    }
  }
}

@media (max-width: 599px) {
  .team_title {
    font-size: 20px;
  }

  .team_content {
    margin-left: 0;
  }

  .board {
    flex-wrap: wrap;
  }

  .board_item {
    padding-right: 5px;
    width: 50%;

    h4 {
      font-size: 24px;
      line-height: 30px;
      display: inline-block;
    }

    p {
      font-size: 16px;
      display: inline-block;
      margin-left: 10px;
    }
  }
}
</style>
